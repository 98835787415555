import "./pagination.css";

function Pagination({ totalPages, currentPage, onPageChange }) {
  const handlePrev = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };
  return (
    <div className="pagination">
      <button
        className="btn1"
        onClick={handlePrev}
        disabled={currentPage === 1}
      >
        <span className="prev__pagination">&larr;</span> prev
      </button>
      <ul className="pagination__list">
        {Array.from({ length: totalPages }, (_, index) => (
          <li
            key={index}
            className={`pagination__item ${
              currentPage === index + 1 ? "active-pagination" : ""
            }`}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </li>
        ))}
      </ul>
      <button
        className="btn2"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        next <span className="next__pagination">&rarr;</span>{" "}
      </button>
    </div>
  );
}

export default Pagination;
