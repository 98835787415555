import { useEffect, useState } from "react";
import { projectsData, projectsNav } from "./Data";
import PortfolioItems from "./PortfolioItems";
import Pagination from "../paginations/Pagination";

const ITEMS_PER_PAGE = 2; // Số mục mỗi trang

function PortfolioFilter() {
  const [item, setItem] = useState({ name: "all" });
  const [projects, setProjects] = useState(projectsData); // Khởi tạo bằng toàn bộ dữ liệu
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [active, setActive] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Hàm lọc dữ liệu theo danh mục
  useEffect(() => {
    const filtered =
      item.name === "all"
        ? projectsData // Nếu chọn "all", lấy toàn bộ dự án
        : projectsData.filter((project) => project.category === item.name);

    setProjects(filtered);
    setCurrentPage(1); // Reset về trang đầu tiên khi đổi danh mục
  }, [item]);

  // Phân trang cho dữ liệu đã lọc
  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setFilteredProjects(projects.slice(startIndex, endIndex));
  }, [projects, currentPage]);

  // Xử lý sự kiện khi nhấp vào danh mục
  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent });
    setActive(index);
  };

  return (
    <div>
      {/* Bộ lọc danh mục */}
      <div className="work__filters">
        {projectsNav.map((navItem, index) => (
          <span
            key={index}
            onClick={(e) => handleClick(e, index)}
            className={`work__item ${active === index ? "active-work" : ""}`}
          >
            {navItem.name}
          </span>
        ))}
      </div>

      {/* Danh sách các dự án */}
      <div className="work__container container grid">
        {filteredProjects.map((item) => (
          <PortfolioItems item={item} key={item.id} />
        ))}
      </div>

      {/* Phân trang */}
      <Pagination
        totalPages={Math.ceil(projects.length / ITEMS_PER_PAGE)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}

export default PortfolioFilter;
