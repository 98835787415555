import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

import Work6 from "../../assets/project_1.png";
import Work7 from "../../assets/project_2.png";
import Work8 from "../../assets/project_3.png";

export const projectsData = [
  {
    id: 1,
    image: Work6,
    title: "Online story website",
    category: "web",
    linkProject: "https://truyenonline.netlify.app/",
  },
  {
    id: 2,
    image: Work7,
    title: "Fast React Pizza",
    category: "web",
    linkProject: "https://proj-fast-react-pizza.netlify.app/",
  },
  {
    id: 3,
    image: Work8,
    title: "Clock",
    category: "web",
    linkProject: "https://bespoke-bublanina-129f52.netlify.app/",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
];
