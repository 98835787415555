import Image4 from "../../assets/testimonial4.png";
import Image6 from "../../assets/testimonial6.png";
import Image9 from "../../assets/testimonial9.png";

export const Data = [
  {
    id: 1,
    image: Image4,
    title: "Mallory Hopkins",
    description: "Life is short. Smile while you still have teeth.",
  },
  {
    id: 2,
    image: Image9,
    title: "Jack Ma",
    description:
      "Today is hard, tomorrow will be worse but the day after tomorrow will be sunshine.",
  },
  {
    id: 3,
    image: Image6,
    title: "Albert Einstein",
    description: "I have no special talents. I am only passionately curious.",
  },
];
