function Social() {
  return (
    <div className="home__social">
      <a
        href="https://www.instagram.com/dongvan31/"
        className="home__social-icon"
        target="_blank"
      >
        <i className="uil uil-instagram"></i>
      </a>
      <a
        href="https://www.facebook.com/vandong.ga"
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-facebook-f"></i>
      </a>
      <a
        href="https://github.com/dongpy78"
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-github"></i>
      </a>
    </div>
  );
}

export default Social;
