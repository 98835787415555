import "./portfolio.css";
import PortfolioFilter from "./PortfolioFilter";

function Portfolio() {
  return (
    <section className="portfolio container section" id="project">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">Most recent work</span>
      <PortfolioFilter />
    </section>
  );
}

export default Portfolio;
